/** @jsx jsx */
import { jsx } from "theme-ui";
import { useEffect, useState } from "react";
import InstantSearch from "@components/InstantSearch";
import queryString from "query-string";
import SEO from "@components/seo";
const Search = (props) => {
  const [defaultRefinement, setDefaultRefinement] = useState("");

  useEffect(() => {
    if (props.location) {
      const search = queryString.parse(decodeURI(props.location.search));
      let query = search.query;
      if (search && query) {
        // @ts-ignore
        setDefaultRefinement(query);
      }
    }
  }, [props.location ? props.location.search : null]);

  return (
    <div>
      <SEO
        metadata={{
          metaTitle: "Search",
        }}
        location={props.location}
      />

      <InstantSearch
        isSearch={true}
        defaultQuery={defaultRefinement}
        heading="Search"
      />
    </div>
  );
};

export default Search;
